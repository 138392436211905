import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  // mode: mode,
  // scrollBehavior () {
  //     return { x: 0, y: 0 }
  // },
  routes: [
    {
      path: '',
      redirect: '/user/dashboard'
    },
    ...createCMSRoutes([
      // {
      //   path: '/home',
      //   redirect: '/user/dashboard',
      //   component: () => import('@/views/vaddress/Home')
      // },
      {
        path: '/user',
        redirect: '/user/dashboard',
        component: () => import('@/views/vaddress/user/layout/Layout'),
        meta: {
          auth: isMobileDevice()
        },
        children: [
          {
            path: 'dashboard',
            component: () => import('@/views/vaddress/user/dashboard/Dashboard')
          },
          {
            path: 'orders',
            redirect: '/user/orders/packages',
            component: () => import('@/views/vaddress/base/TabCMSPage'),
            children: [
              {
                path: 'packages',
                component: () => import('@/views/vaddress/user/order/Packages')
              },
              {
                path: 'inprocess',
                component: () => import('@/views/vaddress/user/order/Histories')
              },
              {
                path: 'histories',
                component: () => import('@/views/vaddress/user/order/Histories')
              }
            ]
          },
          {
            path: 'edit',
            component: () => import('@/views/vaddress/base/FormPage')
          },
          {
            path: 'addresses',
            component: () => import('@/views/vaddress/user/addresses/Addresses')
          },
          {
            path: 'setting',
            component: () => import('@/views/vaddress/user/Setting')
          },
          {
            path: 'profile',
            component: () => import('@/views/vaddress/user/Profile')
          }
      ]
    },
      {
        path: '/user/chat',
        component: () => import('@/views/vaddress/user/Chat')
      },
      {
        path: '/user/checkout',
        component: () => import('@/views/vaddress/user/checkout/Checkout'),
        meta: {
          auth: true
        }
      },
      {
        path: '/user/change_password',
        component: () => import('@/views/vaddress/user/ChangePassword')
      },
      {
        path: '/user/checkout/banktransfer',
        component: () => import('@/views/vaddress/UploadPayment'),
        meta: {
          auth: true
        }
      },
      {
        path: '/user/checkout/va',
        component: () => import('@/views/vaddress/VAPayment'),
        meta: {
          auth: true
        }
      },
      {
        path: '/login',
        component: () => import('@/views/vaddress/Auth')
      },
      {
        path: '/register',
        component: () => import('@/views/vaddress/Auth')
      },
      {
        path: '/forgetpassword',
        component: () => import('@/views/vaddress/ForgetPassword')
      },
      {
        path: '/resetpassword',
        component: () => import('@/views/vaddress/ResetPassword')
      },
      {
        path: '/guest',
        component: () => import('@/views/vaddress/Guest')
      },
      {
        path: '/livetracking',
        component: () => import('@/views/vaddress/CostCalculator')
      },
      {
        path: '/costcalculator',
        component: () => import('@/views/vaddress/CostCalculator')
      },
      {
        path: '/proxy',
        component: () => import('@/views/vaddress/Proxy'),
        meta: {
          auth: true
        }
      },
      {
        path: '/topupaccount',
        component: () => import('@/views/vaddress/TopUpAccount'),
        meta: {
          auth: true
        }
      },
      {
        path: '/topup',
        component: () => import('@/views/vaddress/TopUp'),
        meta: {
          auth: true
        }
      },
      {
        path: '/page/*',
        component: () => import('@/views/vaddress/StaticPage')
      }
    ]),
    {
      path: '*',
      redirect: typeof cordova !== 'undefined' ? '/' : '/page/not-found'
    }
  ]
})

export default router

function isMobileDevice () {
  return window.innerWidth > 767
}

function createCMSRoutes (r, parent = '') {
  return r.reduce((p, v) => {
    const path = parent + (v.path.startsWith('/') ? v.path : '/' + v.path)
    const name =
      v.name ||
      path
        .substring(1)
        .split('/')
        .join('.')

    const children = v.children && createCMSRoutes(v.children, path)

    p.push({
      ...v,
      name,
      component: async () => {
        const base = await v.component()
        return {
          extends: base.default,
          computed: {
            _routeName () {
              return name
            },
            _routePath () {
              return path
            },
            _isParent () {
              // Check if there is a child route mounted (exp: Modal)
              const matched = this.$route.matched
              const index = matched.findIndex(m => m.name === this._routeName)
              return index < matched.length - 1
            },
            _isChild () {
              const matched = this.$route.matched
              const index = matched.findIndex(m => m.name === this._routeName)
              return index > 0
            },
            _isModal () {
              return v.meta && v.meta.modal
            }
          }
        }
      },
      children
    })

    return p
  }, [])
}
