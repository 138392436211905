// import App from './App.vue'
// import router from './router'
import Vue from 'vue'
import store from './store'
import Buefy from 'buefy'
import './assets/style/output.css'
import vueScrollBehavior from 'vue-scroll-behavior'
import '@/filters/number.js'
import '@/mixin/action.js'
import '@/mixin/stringReader.js'
import '@/mixin/css.js'
import '@/mixin/modal.js'
import '@/mixin/upload.js'

import vClickOutside from 'v-click-outside'
import VueSocialauth from 'vue-social-auth'
import VueAxios from 'vue-axios'
import VueSocketIO from 'vue-socket.io'
import axios from 'axios'
import Config from './libs/config'

import BaseApi from './libs/baseApi'
import AdminApi from './libs/adminApi'
import portalApi from '@/libs/portalApi'

class VueApp {
    async init () {
      if (typeof cordova !== 'undefined') {
        // const updateDialog = {
        //   appendReleaseDescription: true
        // }
        // window.codePush.sync(null, { updateDialog, installMode: window.InstallMode.IMMEDIATE })
        // document.addEventListener('resume', () => {
        //   window.codePush.sync(null, { updateDialog, installMode: window.InstallMode.IMMEDIATE })
        // })
        // window.open = cordova.InAppBrowser.open
      }
        Vue.use(vClickOutside)
        await Vue.use(VueAxios, axios)
        Vue.use(new VueSocketIO({
          connection: 'https://api10.ezbooking.co', // untuk server
          vuex: {
              store,
              actionPrefix: 'SOCKET_',
              mutationPrefix: 'SOCKET_'
          },
          options: {
            autoConnect: false
          }
        }))
        await Vue.use(Buefy)
        await Vue.component('star-rating', VueStarRating.default) // eslint-disable-line
        Vue.directive('ripple', VRipple.directive) // eslint-disable-line
        Vue.prototype.$portalApi = await portalApi
        var config = new Config()
        await config.mobile()
        await config.web()

        this.config = JSON.parse(localStorage.getItem('config'))

        this._defineRouter()
        this._defineAppComponent()
        this._importStyles()
        this._setupSocialAuth()
        const baseApi = await new BaseApi(this.config)
        const adminApi = new AdminApi(this.config)
        if (this.config.AppType !== 'vaddress') {
          Vue.use(vueScrollBehavior, {
            router: this.router,
            delay: 700
          })
        }
        Vue.prototype.$baseApi = await baseApi.api()
        Vue.prototype.$adminApi = adminApi.api()
        Vue.prototype.$sidebar = Vue.observable({
            show: false,
            value: {}
        })
        Vue.prototype.$headerActive = Vue.observable({
            value: true
        })
        Vue.prototype.$tabs = Vue.observable({
            value: 0
        })
        Vue.prototype.$tempStorage = Vue.observable({
            DeliveryAddress: {},
            PaymentMethod: {},
            Items: []
        })
        Vue.prototype.$config = this.config
        // this.onesignal()
    }

    start () {
      Vue.config.productionTip = false
      new Vue({
          router: this.router,
          store,
          render: h => h(this.AppComponent)
      }).$mount('#app')
  }

  _setupSocialAuth () {
    const { Facebook, FacebookRedirectUri, Google, GoogleRedirectUri } = this.config
    const redirectUri = window.location.origin
    Vue.use(VueSocialauth, {
      providers: {
          facebook: Facebook ? {
            clientId: Facebook,
            redirectUri: FacebookRedirectUri || redirectUri
          } : null,
          google: Google ? {
              clientId: Google,
              redirectUri: GoogleRedirectUri || redirectUri
          } : null
      }
    })
  }

    _defineAppComponent () {
      switch (localStorage.getItem('apptype')) {
        case 'vaddress':
          this.AppComponent = require('./VAddressApp').default
          break
        default:
          this.AppComponent = require('./App').default
          break
      }
    }

    _defineRouter () {
      try {
        this.router = require(`./router/${localStorage.getItem('apptype')}`).default
      } catch {
        this.router = require('./router').default
      }
    }

    _importStyles () {
      if (typeof cordova !== 'undefined') {
        if (window.device.platform.toLowerCase() === 'ios') return
        const style = document.createElement('style')
        style.type = 'text/css'
        style.appendChild(document.createTextNode('* { -webkit-tap-highlight-color: transparent;-webkit-user-select: none;user-select: none; }'))
        document.head.appendChild(style)
      }
      try {
        require(`./assets/style/${localStorage.getItem('apptype')}.scss`)
      } catch {
        require('buefy/dist/buefy.css')
      }
    }
}

const vueapp = new VueApp()

async function start () {
    await vueapp.init()
    vueapp.start()
}

if (typeof cordova !== 'undefined') {
  document.addEventListener('deviceready', () => {
    start()
  }, false)
} else {
  start()
}

export default vueapp
